import React, { ReactElement, useState } from "react";
import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table, Tooltip, Button, Input, InputAdornment, Box, Typography, styled } from "@mui/material";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { InfoOutlined } from "tp/shared/common-design/icons";
import { securityApiKeysApiKeyRefRevokePost, securityApiKeysPost } from "tp/shared/timeplan-api-client";
import { Schemas } from "tp/shared/timeplan-api-schema";
import { CopyToClipboardButton } from "../../../common/components/buttons/CopyToClipboardButton";
import { DatesToString } from "tp/shared/types";
import { StatusCell } from "./StatusCell";
import { DateCell } from "./DateCell";
import { FormattedMessage, useIntl } from "react-intl";
import { ApiKeysTable as Messages } from "../../messages";
import { LocalizationProvider } from "tp/views/common/components";
import { CreateKeyDialog, CreateKeyDialogProps } from "./CreateKeyDialog";
import NewButton from "tp/views/common/components/buttons/NewButton";

export type ApiKey = Schemas["ApiKey"];

export interface ApiKeysTableProps {
    userRef: number
    keys: ApiKey[]
    shortDateFormat: string
    dateTimeFormat: string
}

interface ApiKeysTableEvents {
    onCreateKey: (userRef: number, expirationMonths: number) => Promise<void>
    onRevokeKey: (apiKeyRef: number) => Promise<void>
}

const TableHeadCell = styled(TableCell)({
    whiteSpace: "nowrap"
});

export function _ApiKeysTable(props: ApiKeysTableProps & ApiKeysTableEvents): ReactElement {
    const [isCreating, setIsCreating] = useState(false);

    const { shortDateFormat, dateTimeFormat } = props;
    const dateCellProps = { shortDateFormat, dateTimeFormat };

    const orderedKeys = orderBy(
        props.keys,
        [k => k.status === "Active", k => k.createdAt, k => k.expiresAt, k => k.lastUsage],
        ["desc", "desc", "desc", "desc"]
    );

    const handleCreateKey: CreateKeyDialogProps["onCreateKey"] = async (selectedExpiration) => {
        await props.onCreateKey(props.userRef, selectedExpiration);
        setIsCreating(false);
    };

    const handleRevokeKey = async (apiKeyRef: number) => await props.onRevokeKey(apiKeyRef);

    return (
        <>
            <Box textAlign="end" marginBottom={1}>
                <NewButton onClick={() => setIsCreating(true)} />
            </Box>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableHeadCell sx={{ whiteSpace: "nowrap" }}>
                                <FormattedMessage {...Messages.Value} />
                                <Tooltip title={<FormattedMessage {...Messages.ValueExplanation} />}>
                                    <InfoOutlined fontSize="small" color="info" sx={{ verticalAlign: "text-bottom", marginLeft: 1 }} />
                                </Tooltip>
                            </TableHeadCell>
                            <TableHeadCell><FormattedMessage {...Messages.Status} /></TableHeadCell>
                            <TableHeadCell><FormattedMessage {...Messages.CreatedAt} /></TableHeadCell>
                            <TableHeadCell><FormattedMessage {...Messages.ExpiresAt} /></TableHeadCell>
                            <TableHeadCell><FormattedMessage {...Messages.LastUsage} /></TableHeadCell>
                            <TableHeadCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderedKeys.map(apiKey => (
                            <TableRow
                                key={apiKey.apiKeyRef}
                                sx={apiKey.status === "Active" ? undefined : { color: "text.disabled" }}>
                                <TableCell sx={{ color: "inherit" }}>
                                    {
                                        apiKey.value
                                            ? <Input
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <CopyToClipboardButton value={apiKey.value} />
                                                    </InputAdornment>}
                                                readOnly
                                                value={apiKey.value}
                                                sx={{ fontSize: "inherit" }}
                                                inputProps={{ style: { textOverflow: "ellipsis" } }} />
                                            : apiKey.maskedValue
                                    }
                                </TableCell>
                                <StatusCell status={apiKey.status} sx={{ color: "inherit" }} />
                                <DateCell date={apiKey.createdAt} sx={{ color: "inherit" }} {...dateCellProps} />
                                <DateCell date={apiKey.expiresAt} sx={{ color: "inherit" }} {...dateCellProps} />
                                <DateCell date={apiKey.lastUsage} sx={{ color: "inherit" }} {...dateCellProps} />
                                <TableCell>{apiKey.status === "Active" &&
                                    <Button variant="outlined" color="error" onClick={() => handleRevokeKey(apiKey.apiKeyRef)}>
                                        <FormattedMessage {...Messages.Revoke} />
                                    </Button>}
                                </TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
                {orderedKeys.length === 0 && (
                    <Typography fontStyle="italic" padding={1} sx={{ textAlign: "center" }}>
                        <FormattedMessage {...Messages.NoKeys} />
                    </Typography>)}
            </TableContainer>
            <CreateKeyDialog open={isCreating} onCreateKey={handleCreateKey} onClose={() => setIsCreating(false)} />
        </>
    );
}

const messages = { sv: { ...require("../../intl/sv.json") } };

export function ApiKeysTable(props: DatesToString<ApiKeysTableProps>): ReactElement {
    const { locale } = useIntl();

    const [keys, setKeys] = useState<ApiKey[]>(() => props.keys.map(k => ({
        ...k,
        createdAt: DateTime.fromISO(k.createdAt),
        expiresAt: k.expiresAt && DateTime.fromISO(k.expiresAt),
        lastUsage: k.lastUsage && DateTime.fromISO(k.lastUsage)
    })));

    const handleCreateKey: ApiKeysTableEvents["onCreateKey"] = async (userRef, expiryMonths) => {
        const res = await securityApiKeysPost({ userRef, expiryMonths });
        setKeys([res, ...keys]);
    };

    const handleRevokeKey: ApiKeysTableEvents["onRevokeKey"] = async apiKeyRef => {
        await securityApiKeysApiKeyRefRevokePost(apiKeyRef);
        setKeys(keys.map(k => k.apiKeyRef === apiKeyRef ? { ...k, status: "Revoked" } : k));
    };

    return (
        <LocalizationProvider localizedMessages={messages} locale={locale}>
            <_ApiKeysTable userRef={props.userRef} keys={keys} onCreateKey={handleCreateKey} onRevokeKey={handleRevokeKey} dateTimeFormat={props.dateTimeFormat} shortDateFormat={props.shortDateFormat} />
        </LocalizationProvider>
    );
}