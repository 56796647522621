import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { OkButton } from "../../../../views/common/components/buttons/ActionButtons";
import { useIntl } from "react-intl";

export interface ErrorDialogProps {
    error: {
        title?: string,
        detail?: string
    } | null,
    onOkClick: () => void
}

export default function ErrorDialog(props: ErrorDialogProps): React.ReactElement {
    const intl = useIntl();
    const { error, onOkClick } = props;
    const [prevError, setPrevError] = useState<ErrorDialogProps["error"]>(undefined);

    const content = error?.detail ?? prevError?.detail ?? intl.formatMessage({ id: "GenericError.Message" });
    const isHtmlPage = content.includes("<!DOCTYPE html>");

    return <Dialog
        open={!!error}
        maxWidth={ isHtmlPage ? "lg" : "sm" }
        fullWidth
    >
        <DialogTitle>
            {error?.title ?? prevError?.title ?? intl.formatMessage({ id: "GenericError.Title" })}
        </DialogTitle>
        <DialogContent style={{ whiteSpace: "pre-wrap" }}>
            {isHtmlPage
                ? <iframe srcDoc={content} style={{ width: "calc(100% - 2* 24px)", height: "80vh" }}></iframe>
                : <Typography>{content}</Typography>
            }
        </DialogContent>
        <DialogActions>
            <OkButton onClick={() => {
                setPrevError(error);
                onOkClick();
            }} />
        </DialogActions>
    </Dialog>;
}
