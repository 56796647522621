import Button, { ButtonProps } from "@mui/material/Button";
import React, { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { White } from "../../../../shared/common-design/colors";
import {
    TpButtonFontSize,
    TpButtonFontWeight,
    TpButtonLetterSpacing,
} from "../../../common-design/fonts";

const StyledButton = styled(Button)(
    ({ theme }) => {
        return {    
            backgroundColor: theme.palette.primary.main,
            color: White,
            borderRadius: "20px",
            fontWeight: TpButtonFontWeight,
            letterSpacing: TpButtonLetterSpacing,
            fontSize: TpButtonFontSize,
        };
    }
);

export default function PrimaryButton(props: ButtonProps): ReactElement {

    return (
        <StyledButton
            {...props}
            variant="contained"
        >
            {props.children}
        </StyledButton>
    );
}
