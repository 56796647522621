import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { TPTurquoise, White } from "tp/shared/common-design/colors";
import { marginLg, marginMd } from "tp/views/common-design/margins";
import { Common } from "tp/views/common/messages";
import { validateEmail, validatePersonName } from "../../../../../common/validation";
import { UPDATE_EMPLINFO } from "../../actions";
import { useDigSigLogSelector } from "../../redux";
import { wizardIntl } from "../DigitalSigningWizard";

const PREFIX = "EmployeeInfoFields";

const classes = {
    root: `${PREFIX}-root`
};

const StyledTextField
 = styled(TextField
 )(() => ({
     [`& .${classes.root}`]: {
         "& .MuiOutlinedInput-notchedOutline": {
             border: `solid 2px ${TPTurquoise}`
         }
     }
 }));

export type EmpInfoType = "employerInfo" | "employeeInfo";
type EmpLabelType = "name" | "email";

type EmpInfoFieldProps = {
    type: EmpInfoType
}

type EmpTextFieldProps = {
    label: EmpLabelType;
    value: string;
    empInfoType: EmpInfoType
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function EmplInfoFields(props: EmpInfoFieldProps): ReactElement {
    const { type: empInfoType } = props;
    const info = useDigSigLogSelector(state => state.digitalSigningLog[empInfoType]);
    const dispatch = useDispatch();

    const handlePersonInfoChange = (e, infoType) => {
        dispatch({
            type: UPDATE_EMPLINFO,
            payload: { [empInfoType]: { ...info, [infoType]: e.target.value } }
        });
    };

    const wrapperStyle = {
        width: "100%",
        marginTop: empInfoType === "employerInfo" ? marginLg : 0
    };

    return (
        <div style={wrapperStyle}>
            <div>
                <Typography variant="subtitle1" style={{ marginBottom: marginMd }}>
                    <FormattedMessage id={wizardIntl(empInfoType)} />:
                </Typography>
                <div>
                    {["name", "email"].map(e => (
                        <EmpTextField
                            key={e}
                            label={e as EmpLabelType}
                            empInfoType={empInfoType}
                            value={info[e]}
                            onChange={x => handlePersonInfoChange(x, e)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

const EmpTextField = (props: EmpTextFieldProps) => {
    const { label, empInfoType, ...restProps } = props;
    const [disabled, setDisabled] = useState(false);

    const textFieldStyle = {
        marginRight: marginMd,
        backgroundColor: White,
    };
    const validated = validateField(label, props.value);

    useEffect(() => {
        setDisabled(
            empInfoType === "employeeInfo" &&
            validated
        );
    }, []);

    const showBorder = !disabled && (!props.value || !validated);

    return (
        <StyledTextField
            {...restProps}
            label={<FormattedMessage {...Common[label]} />}
            style={textFieldStyle}
            disabled={disabled}
            size="small"
            required
            variant="outlined"
            {...(showBorder ? { InputProps: { } } : {})}
        />
    );
};

function validateField(label: EmpLabelType, value: string) {
    if (label === "name") {
        return validatePersonName(value);
    }

    if (label === "email") {
        return validateEmail(value);
    }
}

