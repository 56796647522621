import React from "react";
import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import { Delete as DeleteIcon, CheckCircleRounded } from "tp/shared/common-design/icons";
import { FormattedMessage } from "react-intl";
import { lighterShade, TPRed, White } from "../../../../shared/common-design/colors";
import { Common as Messages } from "../../messages";
import { Common } from "tp/shared/common/messages";

export const OkButton = (props: ButtonProps): React.ReactElement => {
    return (
        <Button
            color="primary"
            variant="contained"
            {...props}>
            <FormattedMessage {...Messages.ok} />
        </Button>
    );
};

export const CompletedButton = (props: ButtonProps): React.ReactElement => {
    return (
        <Button
            color="primary"
            variant="contained"
            startIcon={<CheckCircleRounded />}
            {...props}>
            <FormattedMessage {...Messages.completed} />
        </Button>
    );
};

export const CancelButton = (props: ButtonProps): React.ReactElement => {
    return (
        <Button
            variant="outlined"
            color="primary"
            {...props}>
            <FormattedMessage {...Common.Cancel} />
        </Button>
    );
};

const ButtonDeleteStyle = styled(Button)({
    color: White,
    backgroundColor: TPRed,
    "&:hover": {
        backgroundColor: lighterShade(TPRed, 90)
    }
});

export const DeleteButton = (props: ButtonProps): React.ReactElement => {
    return (
        <ButtonDeleteStyle
            variant="contained"
            startIcon={<DeleteIcon />}
            {...props}
        >
            <FormattedMessage {...Messages.delete} />
        </ButtonDeleteStyle>
    );
};
