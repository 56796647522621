import { defineMessages } from "react-intl";

export const CurrentEventMessages = defineMessages({
    Title: {
        id: "CurrentEvents.Title",
        defaultMessage: "Current events",
    },
    ElapsedTitle: {
        id: "CurrentEvents.ElapsedTitle",
        defaultMessage: "Employed {months} months",
    },
    NoElapsed: {
        id: "CurrentEvents.NoElapsed",
        defaultMessage: "No {months} month contracts during the current period",
    },
    ElapsedEndMissing: {
        id: "CurrentEvents.ElapsedEndMissing",
        defaultMessage: "Employed {months} months (no end date)",
    },
    IndustryExperienceTitle: {
        id: "CurrentEvents.IndustryExperienceTitle",
        defaultMessage: "{ years, plural, one { 1 year work experience } other { {years} years work experience } }",
    },
} as const);

export const OverviewMessages = defineMessages({
    Title: {
        id: "Overview.Title",
        defaultMessage: "Overview",
    },
    Done: {
        id: "Overview.Done",
        defaultMessage: "Nothing left to do.{break}Good job!",
    },
    TimeStampApproval: {
        id: "Overview.TimeStampApproval",
        defaultMessage: "Approvals",
    },
    Messages: {
        id: "Overview.Messages",
        defaultMessage: "Messages",
    },
    LeaveApplications: {
        id: "Overview.LeaveApplications",
        defaultMessage: "Leave requests",
    },
    TimeReportInApp: {
        id: "Overview.TimeReportInApp",
        defaultMessage: "Time reporting in employee app",
    },
    UnsignedContracts: {
        id: "Overview.UnsignedContracts",
        defaultMessage: "Unsigned contracts",
    },
    NoUnsignedContracts: {
        id: "Overview.NoUnsignedContracts",
        defaultMessage: "There are no unsigned contracts",
    },
    UnsignedContractLink: {
        id: "Overview.UnsignedContractLink",
        defaultMessage: "Go to contract",
    },
    LatestReminder: {
        id: "Overview.LatestReminder",
        defaultMessage: "Latest reminder sent {date}",
    },
    NextReminder: {
        id: "Overview.NextReminder",
        defaultMessage: "Next reminder will be sent {date}",
    },
    NoReminder: {
        id: "Overview.NoReminder",
        defaultMessage: "No reminder has been sent or is scheduled to be sent",
    },
    SalaryPeriod: {
        id: "Overview.SalaryPeriod",
        defaultMessage: "For the period {fromDate} to {untilDate}",
    },
    SalaryPeriodAllReported: {
        id: "Overview.SalaryPeriodAllReported",
        defaultMessage: "All time reports are submitted for {fromDate} to {untilDate}",
    },
    Submitted: {
        id: "Overview.Submitted",
        defaultMessage: "Submitted",
    },
    NoSalaryPeriod: {
        id: "Overview.NoSalaryPeriod",
        defaultMessage: "Currently there is no one that shall send in time reports",
    }
} as const);

export const Messages = defineMessages({
    SystemSettings: {
        id: "SystemSettings",
        defaultMessage: "Settings",
    },
    Show: {
        id: "Show",
        defaultMessage: "Show/Hide",
    },
    Birthdays: {
        id: "Birthdays",
        defaultMessage: "Birthdays",
    },
    NoBirthdays: {
        id: "NoBirthdays",
        defaultMessage: "No birthdays during the current period",
    },
    Days: {
        id: "Days",
        defaultMessage: "Number of days",
    },
    ChartSettings: {
        id: "ChartSettings",
        defaultMessage: "Chart settings",
    },
    Deviation: {
        id: "Deviation",
        defaultMessage: "Deviation from budget",
    },
    LastMonth: {
        id: "LastMonth",
        defaultMessage: "Last month",
    },
    Last2Weeks: {
        id: "Last2Weeks",
        defaultMessage: "Last 2 weeks",
    },
    LastWeek: {
        id: "LastWeek",
        defaultMessage: "Last week",
    },
    SalaryPercentile: {
        id: "SalaryPercentile",
        defaultMessage: "Salary percentile",
    },
    Budget: {
        id: "Budget",
        defaultMessage: "Budget, planned and worked time",
    },
    PW: {
        id: "PW",
        defaultMessage: "Planned and worked time",
    },
    Keys: {
        id: "Keys",
        defaultMessage: "Current key information",
    },
    Wrong: {
        id: "Wrong",
        defaultMessage: "Only numbers between 1-99 are accepted",
    },
    NewlyEmployed: {
        id: "NewlyEmployed",
        defaultMessage: "Newly employed",
    },
    Agreement: {
        id: "Agreement",
        defaultMessage: "Collective agreement messages",
    },
    Holidays: {
        id: "Holidays",
        defaultMessage: "Public holidays",
    },
    NoHolidays: {
        id: "NoHolidays",
        defaultMessage: "No holidays during the current period",
    },
    EndOfContract: {
        id: "EndOfContract",
        defaultMessage: "End of contract",
    },
    CloseTab: {
        id: "CloseTab",
        defaultMessage: "Close tab",
    },
    AddWidget: {
        id: "AddWidget",
        defaultMessage: "Add a widget",
    },
    ChooseWidget: {
        id: "ChooseWidget",
        defaultMessage: "Pick a widget to add",
    },
    Yesterday: {
        id: "Yesterday",
        defaultMessage: "Yesterday",
    },
    Today: {
        id: "Today",
        defaultMessage: "Today",
    },
    SickLeave: {
        id: "SickLeave",
        defaultMessage: "Sick leave",
    },
    WorkedTime: {
        id: "WorkedTime",
        defaultMessage: "Worked time",
    },
    PlannedTime: {
        id: "PlannedTime",
        defaultMessage: "Planned time",
    },
    DeviationCode: {
        id: "DeviationCode",
        defaultMessage: "Deviation codes",
    },
    Absence: {
        id: "Absence",
        defaultMessage: "Absence",
    },
    Employments: {
        id: "Employments",
        defaultMessage: "Employment",
    },
    NoSick: {
        id: "NoSick",
        defaultMessage: "No sick leave data to be displayed",
    },
    NoNewlyEmployed: {
        id: "NoNewlyEmployed",
        defaultMessage: "No newly employed during the current period",
    },
    TemporaryContract: {
        id: "TemporaryContract",
        defaultMessage: "Employed with temporary contract",
    },
    NoTemp: {
        id: "NoTemp",
        defaultMessage: "No employees with temporary contract",
    },
    WarningOpenSalaryPeriod: {
        id: "WarningOpenSalaryPeriod",
        defaultMessage:
            "A salary period is open even though its end is more than 3 weeks past. Refers to {hotelName} {fromDate}-{untilDate}.",
    },
    NoAgreements: {
        id: "NoAgreements",
        defaultMessage: "No agreement messages",
    },
    WarningCollectiveAgreement: {
        id: "WarningCollectiveAgreement",
        defaultMessage: "The collective agreement {description} is not complete",
    },
    WarningMissingHolidays: {
        id: "WarningMissingHolidays",
        defaultMessage: "Public holidays are missing for the next period",
    },
    HoursPerWeekNotSet: {
        id: "HoursPerWeekNotSet",
        defaultMessage: "Hours per week when full time is not set",
    },
    DayProfileRotationNotComplete: {
        id: "DayProfileRotationNotComplete",
        defaultMessage: "Notice! Not all weekdays have a day profile",
    },
    ShedNormTimeNotSet: {
        id: "ShedNormTimeNotSet",
        defaultMessage: "No 'Paid time (normtime)' is set",
    },
    NoEndOfContract: {
        id: "NoEndOfContract",
        defaultMessage: "No end of contract during the current period",
    },
    WorkPermission: {
        id: "WorkPermission",
        defaultMessage: "Permission to work abroad end date",
    },
    NoWorkPerm: {
        id: "NoWorkPerm",
        defaultMessage: "No permission to work abroad end during the current period",
    },
    IndustryExperience: {
        id: "IndustryExperience",
        defaultMessage: "Industry experience",
    },
    NoIndustryExperience: {
        id: "NoIndustryExperience",
        defaultMessage: "No industry experience during the current period",
    },
    HelpDesk: {
        id: "HelpDesk",
        defaultMessage: "Help desk",
    },
    OpeningHours: {
        id: "OpeningHours",
        defaultMessage: "Opening hours",
    },
    Monday: {
        id: "Monday",
        defaultMessage: "Monday",
    },
    Friday: {
        id: "Friday",
        defaultMessage: "Friday",
    },
    ClosedForLunch: {
        id: "ClosedForLunch",
        defaultMessage: "Closed for lunch",
    },
    Contact: {
        id: "Contact",
        defaultMessage: "Contact",
    },
    NoData: {
        id: "NoData",
        defaultMessage: "No data for the current period",
    },
    WillBe: {
        id: "WillBe",
        defaultMessage: "will be",
    },
    Loading: {
        id: "Loading",
        defaultMessage: "Loading...",
    },
    Delete: {
        id: "Delete",
        defaultMessage: "Delete",
    },
    Cancel: {
        id: "Cancel",
        defaultMessage: "Cancel",
    },
    TimeInterval: {
        id: "TimeInterval",
        defaultMessage: "Time interval:",
    },
    NoDeviation: {
        id: "NoDeviation",
        defaultMessage: "No deviation codes chosen",
    },
    Support: {
        id: "Support",
        defaultMessage: "Support",
    },
    LinkToVersions: {
        id: "LinkToVersions",
        defaultMessage: "New features in Timeplan",
    },
    ComputedFrom: {
        id: "ComputedFrom",
        defaultMessage: "Computed from the following:",
    },
    SystemAccountGroups: {
        id: "SystemAccountGroups",
        defaultMessage: "System account groups",
    },
    AccountType: {
        id: "AccountType",
        defaultMessage: "Account type",
    },
    AccountName: {
        id: "AccountName",
        defaultMessage: "Account name",
    },
    WORKED_TIME_REPORT: {
        id: "SystemAccountGroupNames.WORKED_TIME_REPORT",
        defaultMessage: "Accounts used in calculation of Worked time in reports",
    },
    Sick_leave: {
        id: "SystemAccountGroupNames.Sick_leave",
        defaultMessage: "Sick leave",
    },
    BudgetTime: {
        id: "BudgetTime",
        defaultMessage: "Budget",
    },
    Vacation: {
        id: "Vacation",
        defaultMessage: "Vacation",
    },
    ShowReport: {
        id: "ShowReport",
        defaultMessage: "Show report",
    },
    AmountCalendarYear: {
        id: "AmountCalendarYear",
        defaultMessage: "Amount during current calendar year."
    }
} as const);

export const OnboardingMessages = defineMessages({
    WelcomeTitle: {
        id: "Onboarding.WelcomeTitle",
        defaultMessage: "Welcome to Timeplan!",
    },
    WelcomeSubtitle: {
        id: "Onboarding.WelcomeSubTitle",
        defaultMessage: "Feel free to watch our introductory video for some tips to get started quickly.",
    },
    OrganizationTitle: {
        id: "Onboarding.OrganizationTitle",
        defaultMessage: "Configure your company in Timeplan"
    },
    OrganizationSubtitle: {
        id: "Onboarding.OrganizationSubtitle",
        defaultMessage: "Structure your company in tasks, locations, projects or roles. This will make it easier when you schedule or when your employees do their time reporting."
    },
    ComparePlansLink: {
        id: "Onboarding.ComparePlansLink",
        defaultMessage: "Compare plans!"
    },
    ComparePlansTitle: {
        id: "Onboarding.ComparePlansTitle",
        defaultMessage: "Compare plans"
    },
    GoBackToPlans: {
        id: "Onboarding.GoBackToPlans",
        defaultMessage: "Back"
    },
    PaymentInfo: {
        id: "Onboarding.PaymentInfo",
        defaultMessage: "Learn more about our payment method"
    },
    PaymentDetails: {
        id: "Onboarding.PaymentDetails",
        defaultMessage: "Employee refers to all people in the personnel list, regardless of usage. You'll be invoiced in groups of 5, meaning if you've got 44 employees in Timeplan, you'll be invoiced for 45."
    },
    CurrentPlan: {
        id: "Onboarding.CurrentPlan",
        defaultMessage: "Your current plan"
    },
    Employee: {
        id: "Onboarding.Employee",
        defaultMessage: "employee"
    },
    Month: {
        id: "Onboarding.Month",
        defaultMessage: "month"
    },
    ChooseYourPlanTitle: {
        id: "Onboarding.ChooseYourPlanTitle",
        defaultMessage: "Choose your plan"
    },
    ChooseYourPlanSubtitle: {
        id: "Onboarding.ChooseYourPlanSubtitle",
        defaultMessage: "Timeplan has different plans, for different use cases. Select which plan you want to start with, you can always change it later."
    },
    ChoosePlanButtonText: {
        id: "Onboarding.ChoosePlanButtonText",
        defaultMessage: "Choose plan"
    },
    BookADemoButtonText: {
        id: "Onboarding.BookADemoButtonText",
        defaultMessage: "Book a demo"
    },
    ContactSales: {
        id: "Onboarding.ContactSales",
        defaultMessage: "Contact sales"
    },
    FreeForeverText: {
        id: "Onboarding.FreeForeverText",
        defaultMessage: "Free forever"
    },
    FreeForAmountOfDaysText: {
        id: "Onboarding.FreeForAmountOfDaysText",
        defaultMessage: "Free for {days} days"
    },
    NoCreditCardNeeded: {
        id: "Onboarding.NoCreditCardNeeded",
        defaultMessage: "No credit card needed"
    },
    CurrentEmployeeCount: {
        id: "Onboarding.CurrentEmployeeCount",
        defaultMessage: "Currently you have {count} employees"
    },
    YourTrialEnds: {
        id: "Onboarding.YourTrialEnds",
        defaultMessage: "Your trial ends on the {trialEndDate, date, long}"
    },
    YourTrialEnded: {
        id: "Onboarding.YourTrialEnded",
        defaultMessage: "Your trial ended on the {trialEndDate, date, long}"
    },
    ChooseYourAddons: {
        id: "Onboarding.ChooseYourAddons",
        defaultMessage: "Choose your addons"
    },
    YouCanAlwaysChangeItLater: {
        id: "Onboarding.YouCanAlwaysChangeItLater",
        defaultMessage: "You can always change it later"
    },
    Addon: {
        id: "Onboarding.Addon",
        defaultMessage: "Addon"
    },
    ChosenItems: {
        id: "Onboarding.ChosenItems",
        defaultMessage: "Chosen items"
    },
    GoToCheckout: {
        id: "Onboarding.GoToCheckout",
        defaultMessage: "Go to checkout"
    },
    ContactUs: {
        id: "Onboarding.ContactUs",
        defaultMessage: "Do you need help? Contact us!"
    },
    AddonLabelText: {
        id: "Onboarding.AddonLabelText",
        defaultMessage: "Addon"
    },
    CancelAnytime: {
        id: "Onboarding.CancelAnytime",
        defaultMessage: "Cancel anytime"
    },
    From: {
        id: "Onboarding.From",
        defaultMessage: "from"
    },
    SubmitError: {
        id: "Onboarding.SubmitError",
        defaultMessage: "Something went wrong while submitting. Please <a>contact support</a> in case it happens again"
    },
    WellDone: {
        id: "Onboarding.WellDone",
        defaultMessage: "Well done!"
    },
    AfterOnboardingText: {
        id: "Onboarding.AfterOnboardingText",
        defaultMessage: "You’ve added your employees to Timeplan!\nOn the dashboard, you’ll find links to our help center and our guide that takes you through the basic parts of using Timeplan."
    },
    StartGuide: {
        id: "Onboarding.StartGuide",
        defaultMessage: "Start guide"
    }
} as const);

export const StaffTableMessages = defineMessages({
    StepTitle: {
        id: "Onboarding.StaffTable.StepTitle",
        defaultMessage: "Add your staff"
    },
    StepSubtitle: {
        id: "Onboarding.StaffTable.StepSubtitle",
        defaultMessage: "Add your staff manually or import them from Excel by copying all values and then pasting them into the first column."
    },
    FirstName: {
        id: "Onboarding.StaffTable.FirstName",
        defaultMessage: "First name"
    },
    LastName: {
        id: "Onboarding.StaffTable.LastName",
        defaultMessage: "Last name"
    },
    Email: {
        id: "Onboarding.StaffTable.Email",
        defaultMessage: "E-mail"
    },
    Department: {
        id: "Onboarding.StaffTable.Department",
        defaultMessage: "Department"
    },
    RolePlaceholder: {
        id: "Onboarding.StaffTable.RolePlaceholder",
        defaultMessage: "Salesman"
    },
    CollectiveAgreementPlaceholder: {
        id: "Onboarding.StaffTable.CollectiveAgreementPlaceholder",
        defaultMessage: "Full time 1h break"
    },
    HoursPerWeek: {
        id: "Onboarding.StaffTable.HoursPerWeek",
        defaultMessage: "Hours per week"
    },
    StartDate: {
        id: "Onboarding.StaffTable.StartDate",
        defaultMessage: "Start date"
    },
    CollectiveAgreement: {
        id: "Onboarding.StaffTable.CollectiveAgreement",
        defaultMessage: "Usage"
    },
    NoRowsPromt: {
        id: "Onboarding.StaffTable.NoRowsPromt",
        defaultMessage: "Press the \"add\" button to add new rows",
    }
} as const);

export const TpGoMessages = defineMessages({
    TrialExpired: {
        id: "TpGo.TrialExpired",
        defaultMessage: "Your trial has expired. Add payment details to continue using Timeplan Plus."
    },
    TrialWillExpire: {
        id: "TpGo.TrialWillExpire",
        defaultMessage: "Your trial will expire in {daysUntilTrialEnd, plural, one {# day} other {# days}}. Add payment details to continue using Timeplan Plus."
    },
    PaymentFailed: {
        id: "TpGo.PaymentFailed",
        defaultMessage: "Payment failed, please check your credit card details."
    },
    AddPaymentDetails: {
        id: "TpGo.AddPaymentDetails",
        defaultMessage: "Add payment details"
    }
} as const);

export const SendInviteMessages = defineMessages({
    Title: {
        id: "Onboarding.SendInviteStep.Title",
        defaultMessage: "Invite to the mobile app",
    },
    Description: {
        id: "Onboarding.SendInviteStep.Description",
        defaultMessage:
            "When you invite your staff to use the mobile app they will receive an email with a link to the App Store, Google Play with an activation code. You can invite your staff later if you do not want to do it right now.",
    },
    SelectAll: {
        id: "Onboarding.SendInviteStep.SelectAll",
        defaultMessage: "Select all",
    }
} as const);
