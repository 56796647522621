import { Button, ButtonProps } from "@mui/material";
import React, { ReactElement } from "react";
import { borderRadius } from "../../../common-design/borders";
import { Gray1, TPBlue, TPTurquoise_Light, White } from "../../../../shared/common-design/colors";

/**
 * A big juicy confirm button
 */

export function ConfirmButton(props: ButtonProps): ReactElement {
    const { style, ...buttonProps } = props;

    const buttonStyle = {
        borderRadius: borderRadius * 4,
        height: "50px",
        width: "50%",
        fontSize: "18px"
    };
    const activeStyle = {
        backgroundColor: TPBlue,
        color: White
    };

    const disabledStyle = {
        backgroundColor: TPTurquoise_Light,
        color: Gray1,
    };

    const combinedStyle = {
        ...buttonStyle,
        ...(buttonProps.disabled ? disabledStyle : activeStyle),
        ...style
    };

    return (
        <Button {...buttonProps} style={combinedStyle}>
            {props.children}
        </Button>
    );
}