import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, Toolbar, Button, IconButton, AppBar } from "@mui/material";
import { Close as CloseIcon, Print as PrintIcon } from "tp/shared/common-design/icons";
import { FormattedMessage } from "react-intl";
import * as runtime from "tp/shared/common/reducers/runtime";
import * as margins from "tp/views/common-design/margins";
import { Common } from "tp/views/common/messages";

const PREFIX = "PrintContractPopUp";

const classes = {
    rightToolbar: `${PREFIX}-rightToolbar`
};

const StyledDialog = styled(Dialog)({
    [`& .${classes.rightToolbar}`]: {
        marginLeft: "auto",
        marginRight: -12
    }
});

export interface PrintContractPopUpProps {
    open: boolean;
    handleClose: () => void;
    contractRef: string;
    classes: null;
}

export function PrintContractPopUp(props: PrintContractPopUpProps): React.ReactElement {
    const { open, contractRef } = props;

    const contractFrame = useRef<HTMLIFrameElement>(null);
    const handleClose = (): void => {
        props.handleClose();
    };

    function printReport() {
        if (contractFrame.current.contentWindow) {
            contractFrame.current.contentWindow.print();
        }
    }

    return (
        <StyledDialog
            open={open}
            fullWidth={true}
            maxWidth="md"
        >
            <div style={{ overflow: "hidden" }}>
                <AppBar position="relative">
                    <Toolbar className={classes.rightToolbar}>
                        <Button autoFocus color="inherit" onClick={printReport} startIcon={<PrintIcon />}>
                            {<FormattedMessage {...Common.print} />}
                        </Button>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div id="divcontents" style={{ marginBottom: margins.marginLg, overflow: "visible" }}>
                        <iframe
                            ref={contractFrame}
                            src={`${runtime.getUrls().apiUrl}/report?contractRef=${contractRef}&showSignatures=true`}
                            width="90%"
                            height="550px"
                            frameBorder="0"
                            style={{ display: "block", margin: "auto" }}
                        />
                    </div>
                </DialogContent>
            </div>
        </StyledDialog>
    );
}