import { DateTime } from "luxon";

export const MINUTE_SECONDS = 60;
export const HOUR_SECONDS = 3600;
export const DAY_SECONDS = 86400;

/**
 * @param seconds duration in seconds
 * @returns a string formatted as "mm:ss" if duration is less than 1h, else as "hh:mm:ss".
 */
export function formatDurationWithSeconds(seconds: number): string {
    const durationAsDate = new Date(seconds * 1000).toISOString();
    if (seconds < HOUR_SECONDS) {
        return durationAsDate.substring(14, 19);
    } else {
        return durationAsDate.substring(11, 19);
    }
}

// https://stackoverflow.com/questions/10073699/pad-a-number-with-leading-zeros-in-javascript
export function pad(num: number, width: number, z = undefined): string {
    z = z || "0";
    const s = num + "";
    return s.length >= width ? s : new Array(width - s.length + 1).join(z) + s;
}

export function formatTimeDuration(seconds: number): string {
    if (seconds === null || seconds === undefined) {
        return "";
    } else if (seconds === 0) {
        return "00:00";
    } else if (seconds < 0) {
        return formatTimeDuration(DAY_SECONDS + seconds);
    }

    const h = Math.floor(seconds / HOUR_SECONDS);
    let hours;

    if (h < 100) {
        hours = pad(h, 2);
    } else if (h < 1000) {
        hours = pad(h, 3);
    } else {
        hours = pad(h, 4);
    }

    const minutesNumber = Math.round((seconds / MINUTE_SECONDS) % MINUTE_SECONDS);
    const minutesStr = pad(minutesNumber, 2);

    return `${hours}:${minutesStr}`;
}

export function formatTimeRelative(seconds: number): string {
    if (seconds < 0) {
        return "-" + formatTimeDuration(-seconds);
    } else {
        return formatTimeDuration(seconds);
    }
}

export function formatTimeDurationDay(seconds: number): string {
    return formatTimeDuration(seconds % DAY_SECONDS);
}

export function formatPeriod(fromSec: number, untilSec: number): string {
    return `${formatTimeDurationDay(fromSec)} - ${formatTimeDurationDay(untilSec)}`;
}

export function capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatCurrencyFromMinorToMajorUnit(minorAmount: number, currencyCode: string): number {
    switch (currencyCode) {
        case "JPY":
            return minorAmount;
        default:
            return minorAmount / 100;
    }
}

export const dateToString = (value: DateTime, format: string): string => {
    return value && value.toFormat(format);
};

export const parseUrl = (url: string): URL | null => {
    try {
        return new URL(url);
    } catch {
        return null;
    }
};